<template>
    <main class="clean">
        <BannerHome/>
    <section class="block-info bg-light pb-2 pt-4">
        <Info/>
    </section>
    <section class="ftco-section bg-cc pb-4 mt-2 wow slideInUp">
        <div class="col-md-12 heading-section text-center mb-2">
            <h2 class="mb-4">ESTADOS</h2>
        </div>
        <States v-on:getCity="goCity"/>
    </section>
    <section class="ftco-section bg-light mb-4 wow slideInUp">
        <div class="container">
            <div class="row justify-content-center mb-2 pb-3">
                <div class="col-md-7 heading-section text-center ftco-animate fadeInUp ftco-animated">
                    <h2 class="mb-4">PROPIEDADES DESTACADAS</h2>
                </div>
            </div>
            <div class="row d-flex wow slideInUp">
                <PropertyCard
                v-for="(item, i) in properties"
                    :key="item.folio"
                    :name="item.folio"
                    :index="i"
                    :item="item"
                />
            </div>
        </div>
    </section>
    <!-- <section>
         <div class="container">
            <img class="img-home" src="../../../public/images/examples/ccbanner.jpg">
        </div>
    </section> -->
<!-- **productos destacados** -->

    <!-- <section class="ftco-section bg-light">
        <div class="container">
            <div class="row justify-content-center mb-2 pb-3">
                <div class="col-md-7 heading-section text-center ftco-animate fadeInUp ftco-animated">
                    <h2 class="mb-4">PRODUCTOS DESTACADOS</h2>
                </div>
            </div>
            <div class="row d-flex">
                <GeneralCard/>
                <GeneralCard/>
                <GeneralCard/>
                <GeneralCard/>
            </div>
        </div>
    </section> -->

<!-- **autos destacados** -->

    <!-- <section class="ftco-section bg-light">
        <div class="container">
            <div class="row justify-content-center mb-2 pb-3">
                <div class="col-md-7 heading-section text-center ftco-animate fadeInUp ftco-animated">
                    <h2 class="mb-4">AUTOS DESTACADOS</h2>
                </div>
            </div>
            <div class="row d-flex">
                <CarCard/>
                <CarCard/>
                <CarCard/>
                <CarCard/>
            </div>
        </div>
    </section> -->

    </main>
</template>

<script>
import { mapGetters } from "vuex";
// import Banner from "@/components/layout/Banner.vue"}
import NotResults from '@/components/layout/NotFound';
import BannerHome from "@/components/layout/BannerHome.vue";
import States from "@/components/states/States.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import PropertyHorCard from "@/components/properties/PropertyHorCard.vue";
import CarCard from "@/components/cars/CarCard.vue";
import Icons from "@/components/layout/Icons.vue";
import GeneralCard from "@/components/products/GeneralCard.vue";
import Info from "@/components/information/Info.vue";
import Preloader from "@/components/layout/Preloader.vue";

export default {
    data() {
        return {
        search: {
            state: "",
            keyword: "",
            city: "",
            category: "",
            type: ""
        }
        };
    },
    components: {
        // Banner,
        BannerHome,
        States,
        PropertyCard,
        PropertyHorCard,
        CarCard,
        Icons,
        GeneralCard,
        SearchForm,
        Info,
        Preloader
    },
   data() {
     return {
       codeShareProperties: 0,
     };
   },
    methods: {
      goCity(datos) {
       this.search = datos;
       this.$store.dispatch("searchProducts",this.search);
      }
   },
   computed: {
     ...mapGetters({
       properties: "_getProperties"
     }),
   },
   created() {
       this.$store.dispatch("searchProperties");
   }
};
    // new WOW().init();
</script>


<style scoped>
.clean{
    background:#fff !important;
}

.img-home{
width: 100% !important;
}

</style>